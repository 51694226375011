import React from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'

import { HeaderStyled, HeaderNav, HeaderLogo, HeaderNavLi, Hamburger, HiddenMenu } from './Header.styles';

type Props = {
    visible: {
        skills: boolean,
        projects: boolean,
        about: boolean,
        contact: boolean,
        header: boolean
    },
    refs: any,
    handleVisibility: (visible: boolean, section: string) => void
}

const Header: React.FC<Props> = ({ visible, handleVisibility, refs }) => {
    const [menuOpen, setMenuOpen] = React.useState(false)
    const headerRef = React.useRef<HTMLDivElement>(null)

    const handleMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setMenuOpen(prev => !prev)
    }

    const handleLinks = (linkName: string) => {
        setMenuOpen(false)

        switch (linkName) {
            case 'skills':
                window.scrollTo(0, refs.skills.current?.offsetTop || 0)
                return
            case 'projects':
                window.scrollTo(0, refs.projects.current?.offsetTop || 0)
                return
            case 'about':
                window.scrollTo(0, refs.about.current?.offsetTop || 0)
                return
            case 'contact':
                window.scrollTo(0, refs.contact.current?.offsetTop || 0)
                return
            default:
                return
        }
    }

    return (
        <HeaderStyled expand={menuOpen} ref={headerRef}>
            <HeaderLogo><p onClick={() => window.scrollTo(0,0)}>JasonShaw.Dev</p></HeaderLogo>
            <HeaderNav>
                <ul>
                    <HeaderNavLi onClick={() => handleLinks('skills')}>Skills</HeaderNavLi>
                    <HeaderNavLi onClick={() => handleLinks('projects')}>Projects</HeaderNavLi>
                    <HeaderNavLi onClick={() => handleLinks('about')}>About</HeaderNavLi>
                    <HeaderNavLi onClick={() => handleLinks('contact')}>Contact</HeaderNavLi>
                </ul>
            </HeaderNav>
            <Hamburger menuOpen={menuOpen} onClick={handleMenu}>
                {
                    menuOpen ? <FaTimes size={'2rem'} /> : <FaBars size={'2rem'} />
                }
            </Hamburger>
            <HiddenMenu menuOpen={menuOpen}>
                <ul>
                    <HeaderNavLi onClick={() => handleLinks('skills')}>Skills</HeaderNavLi>
                    <HeaderNavLi onClick={() => handleLinks('projects')}>Projects</HeaderNavLi>
                    <HeaderNavLi onClick={() => handleLinks('about')}>About</HeaderNavLi>
                    <HeaderNavLi onClick={() => handleLinks('contact')}>Contact</HeaderNavLi>
                </ul>
            </HiddenMenu>
        </HeaderStyled>
    )
}

export default Header
