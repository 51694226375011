import React from 'react'

import { StyledContact, Icons } from './Contact.styles';
import { FaEnvelope, FaGithub, FaStackOverflow, FaFileAlt } from 'react-icons/fa';

type Props = {
    refs: any
}

const Contact: React.FC<Props> = ({ refs }) => {
    return (
        <StyledContact ref={refs.contact}>
            <Icons>
                <a title='Stack Overflow' href='https://stackoverflow.com/users/14268269/jason' target='_blank' rel="noopener noreferrer"><FaStackOverflow size={'4rem'} /></a>
                <a title='GitHub' href='https://github.com/JSONhoward' target='_blank' rel="noopener noreferrer"><FaGithub size={'4rem'} /></a>
                <a title='Email' href='mailto: jason@jasonshaw.dev'><FaEnvelope size={'4rem'} /></a>
                <a title='Resume' href="/jason%20shaw.docx"><FaFileAlt size={'4rem'} /></a>
            </Icons>
        </StyledContact>
    )
}

export default Contact
