import React from 'react'
import { Transition } from 'react-transition-group'
import { FaArrowUp } from 'react-icons/fa'

import { StyledScrollToTop, ScrollToTopFadeInDuration } from './ScrollToTop.styles';

const ScrollToTop = () => {
    const [reveal, setReveal] = React.useState(false)
    const scrollUpRef = React.useRef<HTMLDivElement>(null)

    React.useLayoutEffect(() => {
        const topPosition = scrollUpRef.current?.getBoundingClientRect().top
        const onScroll = () => {
            const position = window.scrollY + window.innerHeight
            setReveal(topPosition! + 500 < position)
        }
        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    })

    return (
        <Transition in={reveal} timeout={ScrollToTopFadeInDuration}>
            {
                state => (
                    <StyledScrollToTop state={state} onClick={() => window.scrollTo(0,0)} ref={scrollUpRef}>
                        <FaArrowUp size={'1rem'} />
                    </StyledScrollToTop>
                )
            }
        </Transition>
    )
}

export default ScrollToTop
