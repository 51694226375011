import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { styledTheme } from './Styles/styled-components-theme';

ReactDOM.render(
  <React.StrictMode>
      <StyledThemeProvider theme={styledTheme}>
        <App />
      </StyledThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
