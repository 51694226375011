import styled from "styled-components";

export const StyledHero = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.dark};
`;

export const ColorP = styled("p")`
  color: ${(props) => props.theme.primaryColor};
`;

export const HeroText = styled("div")`
  color: white;
  width: 500px;
  font-size: 3rem;
  text-align: center;
  color: whitesmoke;

  p {
    display: inline;
  }

  @media screen and (max-width: 600px) {
    width: 350px;
    font-size: 2rem;
  }

  @media screen and (max-width: 400px) {
    width: 300px;
    font-size: 1.75rem;
  }
`;

export const HeroImage = styled("div")`
  display: grid;
  place-items: center;

  svg {
    height: 10rem;
    width: 10rem;
  }
`;

export const HeroButton = styled("button")`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 4rem;
  width: 15rem;
  border-radius: 5px;
  border: ${(props) => `2px solid ${props.theme.primaryColor}`};
  box-shadow: 1px 1px 5px black;
  background-color: transparent;
  color: whitesmoke;
  font-size: 1.5rem;
  cursor: pointer;
  transition: border 1s, box-shadow 1s;

  p {
    text-align: center;
    font-size: 1.25rem;
  }

  svg {
    margin-right: 0.5rem;
    transform: rotate(0deg);
    transition: color 1s, transform 1s;
    color: whitesmoke;
  }

  &:hover {
    box-shadow: ${(props) => `1px 1px 5px ${props.theme.primaryColor},
            -1px 1px 5px ${props.theme.primaryColor},
            -1px -1px 5px ${props.theme.primaryColor},
            1px -1px 5px ${props.theme.primaryColor}`};

    svg {
      transform: rotate(90deg);
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;
