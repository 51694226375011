import React from 'react'

import { PosterContainer, PosterLeft, PosterMiddle, PosterRight } from './Posters.styles';

type Props = {
    images: {
        image1: string,
        image2: string,
        image3: string
    }
}

const Posters: React.FC<Props> = ({ images }) => {
    const handleLink = (title: string) => {
        if(title.includes('vanilla')) {
            window.open('https://www.imdb.com/title/tt0259711/', '_blank')
        }
        if(title.includes('eternal')) {
            window.open('https://www.imdb.com/title/tt0338013/', '_blank')
        }
        if(title.includes('notebook')) {
            window.open('https://www.imdb.com/title/tt0332280/', '_blank')
        }
        if(title.includes('office')) {
            window.open('https://www.imdb.com/title/tt0386676/', '_blank')
        }
        if(title.includes('house')) {
            window.open('https://www.imdb.com/title/tt0412142/', '_blank')
        }
        if(title.includes('lost')) {
            window.open('https://www.imdb.com/title/tt0411008/', '_blank')
        }
    }

    return (
        <PosterContainer>
            <PosterLeft onClick={() => handleLink(images.image1)} image={images.image1}></PosterLeft>
            <PosterMiddle onClick={() => handleLink(images.image2)} image={images.image2}></PosterMiddle>
            <PosterRight onClick={() => handleLink(images.image3)} image={images.image3}></PosterRight>
        </PosterContainer>
    )
}

export default Posters
