import React from "react";
import { BigHead } from "@bigheads/core";
import { FaArrowRight } from "react-icons/fa";

import {
  StyledHero,
  HeroImage,
  HeroText,
  HeroButton,
  ColorP,
} from "./Hero.styles";
import { Refs } from "../../@types/myTypes";

type Props = {
  refs: Refs;
};

const Hero: React.FC<Props> = ({ refs }) => {
  const [happyEyes, setHappyEyes] = React.useState(false);
  const [squintEyes, setSquintEyes] = React.useState(false);

  const handleScroll = (ref: Refs) => {
    window.scrollTo(0, ref["skills"]!.current!.offsetTop - 50);
  };

  return (
    <StyledHero>
      <HeroText>
        <span>Hello, I'm </span>
        <ColorP>Jason</ColorP>
        <span>. I am</span>
        <br />
        <span>a web developer.</span>
      </HeroText>
      <HeroImage
        onMouseEnter={() => setSquintEyes(true)}
        onMouseLeave={() => setSquintEyes(false)}
      >
        <BigHead
          accessory="none"
          body="chest"
          clothing="shirt"
          clothingColor="black"
          eyebrows="raised"
          eyes={squintEyes ? "squint" : happyEyes ? "happy" : "normal"}
          facialHair="mediumBeard"
          graphic="react"
          hair="none"
          hairColor="black"
          hat="none"
          hatColor="black"
          lashes={false}
          lipColor="pink"
          faceMask={true}
          mouth="open"
          skinTone="brown"
          faceMaskColor={"blue"}
        />
      </HeroImage>
      <HeroButton
        onClick={() => handleScroll(refs)}
        onMouseEnter={() => setHappyEyes(true)}
        onMouseLeave={() => setHappyEyes(false)}
      >
        <p>See Skills/Projects</p>
        <FaArrowRight size={"1.5rem"} />
      </HeroButton>
    </StyledHero>
  );
};

export default Hero;
