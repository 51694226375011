import React from 'react'

import './App.css'
import Home from './pages/Home'
import { StyledApp } from './App.styles';

const App = () => {
  return (
    <StyledApp>
      <Home />
    </StyledApp>
  )
}

export default App;
