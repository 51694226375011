import styled from 'styled-components'

export const StyledAbout = styled('div')`
position: relative;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100vh;
width: 100%;
padding-top: 4rem;
`

export const AboutGrid = styled('div')`
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
gap: 1rem;
`

export const Me = styled('div') <{ me: string }>`
position: relative;
background-image: ${({ me }) => `url(${me})`};
background-position: center;
background-repeat: no-repeat;
background-size: cover;
box-shadow: 5px 5px 5px rgba(0,0,0,.5);
text-shadow: 5px 5px 5px rgba(0,0,0,.5);
margin: .5rem;
`

export const AboutBox = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 40vh;
box-shadow: 5px 5px 5px rgba(0,0,0,.5);
padding: .5rem;
margin: .5rem;
background: ${({ theme: { dark } }) => dark};
`

export const BoxTitle = styled('p')`
font-size: 1.5rem;
font-family: 'Titillium Web', sans-serif;
color: ${({ theme: { primaryColor } }) => primaryColor};
border-bottom: ${({ theme: { primaryColor } }) => `2px solid ${primaryColor}`};

@media screen and (max-width: 800px) {
    font-size: 1.25rem;
}

@media screen and (max-width: 600px) {
    font-size: 1rem;
}
`

export const BoxContents = styled('div')`
position: relative;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
max-width: 350px;
margin: auto;
`

export const Bio = styled('div')`
position: relative;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 90%;

p {
    font-size: 1rem;
}

ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    font-size: 2.5vw;
    color: whitesmoke;
}

li {
    display: flex;
    justify-content: space-between;
    margin: .25rem 0;
}

p:last-child {
    margin: auto;
    font-size: .75rem;
}

li q {
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    width: 80%;
    font-style: italic;
}

@media screen and (min-width: 600px) {
    ul {
        font-size: 1rem;
    }
}

@media screen and (min-width: 800px) {
    ul {
        font-size: 1.5rem;
    }
    p:last-child {
    margin: auto;
    font-size: 1rem;
}
}

@media screen and (min-width: 1000px) {
    width: 70%;
    
}
`

