import styled from 'styled-components'

export const StyledProjectsContainer = styled('div')`
position: relative;
display: flex;
justify-content: center;
height: 100vh;
width: 100%;
overflow-x: hidden;
padding: 4rem 2rem;
background-color: ${props => props.theme.dark};
`

export const StyledProjects = styled('div')`
position: relative;
display: grid;
height: 100%;
width: 100%;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(2, 1fr);
align-content: center;
justify-items: center;
gap: 1rem;
background-color: ${props => props.theme.dark};

@media screen and (orientation: portrait) {
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
}
`