import React from 'react'
import Footer from '../Footer/Footer'
import { LayoutStyled } from './Layout.styles'
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import Header from '../Header/Header';
import { Refs } from '../../@types/myTypes';

const Layout: React.FC = ({children}) => {
    const [visibility, setVisibility] = React.useState({
        skills: false,
        projects: false,
        about: false,
        contact: false,
        header: false
    })

    const refs: Refs = {
        skills: React.useRef<HTMLDivElement>(null),
        projects: React.useRef<HTMLDivElement>(null),
        about: React.useRef<HTMLDivElement>(null),
        contact: React.useRef<HTMLDivElement>(null)
    }

    const handleVisibility = (visible: boolean, section: string) => {
        setVisibility((prev: any) => {
            let state = { ...prev, [section]: visible }
            return state
        })
    }

    const childrenWithProps = React.Children.map(children, child => {
        const props = {visible: visibility, refs, handleVisibility}
        if(React.isValidElement(child)) {
            return React.cloneElement(child, props)
        }
        return child
    })

    return (
        <>
            <LayoutStyled>
                <Header refs={refs} visible={visibility} handleVisibility={handleVisibility} />
                {childrenWithProps}
                <Footer />
                <ScrollToTop />
            </LayoutStyled>
        </>
    )
}

export default Layout
