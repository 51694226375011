import React from 'react'
import { Transition } from 'react-transition-group';
import VizSensor from 'react-visibility-sensor';

import { StyledDivider, DividerTitle } from './Divider.styles';

type Props = {
    title: string,
    offset: number,
}

const Divider: React.FC<Props> = ({ title, offset }) => {
    const [visible, setVisible] = React.useState(false)

    return (
        <VizSensor onChange={(isVisible) => setVisible(isVisible)}>
            <Transition in={visible} timeout={500}>
                {
                    state => (
                        <StyledDivider state={state} offset={offset}>
                            <DividerTitle>{title}</DividerTitle>
                        </StyledDivider>
                    )
                }
            </Transition>
        </VizSensor>
    )
}

export default Divider
