import styled from 'styled-components'

export const FooterStyled = styled('footer')`
display: flex;
align-items: center;
justify-content: center;
font-family: 'Titillium Web', sans-serif;
height: 3rem;
width: 100%;
background-color: ${props => props.theme.primaryColor};
z-index: 3;
`