import React from 'react'

import { StyledSkills } from './Skills.styles';
import { skillsArray } from './Skills.utils';
import SkillsSquare from './SkillsSquare';

type Props = {
    refs: any
}

const Skills: React.FC<Props> = ({ refs }) => {
    let skillCircles = skillsArray.map((el, i) => {
        return (
            <SkillsSquare key={i} color={el.color} title={el.skill}>
                {el.icon}
            </SkillsSquare>
        )
    })

    return (
        <StyledSkills ref={refs.skills}>
            {skillCircles}
        </StyledSkills>
    )
}

export default Skills
