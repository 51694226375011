import crapsCalcMain from '../../images/craps-calc-main.png'
import poolScorer from '../../images/pool-scorer-main.png'
import memory from '../../images/memory-main.png'
import reactResources from '../../images/react-resources-main.png'
import bibleVerse from '../../images/bible-verse-main.png'
import covidDeaths from '../../images/covid-deaths-main.png'

export const projectsArray: {title: string, image: string, description: string, github: string, url: string}[] = [
    {
        title: 'Craps Calc',
        image: crapsCalcMain,
        description: 'Calculator for the many bets and payouts in craps',
        github: 'https://github.com/JSONhoward/craps-calc',
        url: 'https://craps-calculator-web.web.app/'
    },
    {
        title: 'Pool Scorer',
        image: poolScorer,
        description: 'Web app for scoring various pool/billiard games',
        github: 'https://github.com/JSONhoward/pool-scorer',
        url:  'https://poolscorer.com'
    },
    {
        title: 'Memory',
        image: memory,
        description: 'Classic memory game with web development icons',
        github: 'https://github.com/JSONhoward/memory',
        url: 'https://memory-5a764.web.app/'
    },
    {
        title: 'React Resources',
        image: reactResources,
        description: 'Various resources for learning React.js',
        github: 'https://github.com/JSONhoward/react-resources',
        url: 'https://react-resources.web.app/'
    },
    {
        title: 'Random Verse',
        image: bibleVerse,
        description: 'Get random bible verses',
        github: 'https://github.com/JSONhoward/random-verse',
        url: 'https://random-verse.web.app/'
    },
    {
        title: 'U.S. Covid-19 Deaths',
        image: covidDeaths,
        description: 'United States Covid-19 deaths by state and age',
        github: 'https://github.com/JSONhoward/covid-deaths-us',
        url: 'https://us-covid19-deaths.web.app/'
    }
]