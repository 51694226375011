import styled from 'styled-components'

export const StyledSkillsContainer = styled('div')`
position: relative;
display: flex;
justify-content: center;
height: 100vh;
width: 100%;
overflow-x: hidden;
padding: 1rem;
`

export const StyledSkills = styled('div')`
position: relative;
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(3, 1fr);
align-content: center;
gap: 1rem;
`