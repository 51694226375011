import React from 'react'
import { FaGithub, FaLink } from 'react-icons/fa';

import { StyledProjectBox, ProjectImage, ProjectInfo, Title, Description, ProjectLinks } from './ProjectBox.styles';

type Props = {
    index: number,
    image: string,
    title: string,
    description: string,
    url: string,
    github: string
}

const ProjectBox: React.FC<Props> = ({ index, image, title, description, url, github }) => {

    return (
        <StyledProjectBox index={index}>
            <ProjectImage src={image} alt={title} />
            <ProjectInfo>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <ProjectLinks>
                    <span><FaLink size={'1rem'} /><a href={url} target='_blank' rel="noopener noreferrer">View</a></span>
                    <span><FaGithub size={'1rem'} /><a href={github} target='_blank' rel="noopener noreferrer">Github</a></span>
                </ProjectLinks>
            </ProjectInfo>
        </StyledProjectBox>
    )
}

export default ProjectBox
