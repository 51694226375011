import React from 'react'

import { StyledProjects, StyledProjectsContainer } from './Projects.styles';
import { projectsArray } from './Projects.utils';
import ProjectBox from './ProjectBox'

type Props = {
    refs: any
}

const Projects: React.FC<Props> = ({ refs }) => {
    let projectSquares = projectsArray.map((el, i) => {
        return (
            <React.Fragment key={'fragment' + i}>
                <ProjectBox key={i} index={i} image={el.image} title={el.title} description={el.description} url={el.url} github={el.github} />
            </React.Fragment>
        )
    })

    return (
        <StyledProjectsContainer ref={refs.projects}>
            <StyledProjects>
                {projectSquares}
            </StyledProjects>
        </StyledProjectsContainer>
    )
}

export default Projects
