import React from 'react'
import {FaHtml5, FaCss3, FaSass, FaReact, FaNodeJs, FaGithub} from 'react-icons/fa'
import {SiTypescript, SiMongodb, SiJavascript} from 'react-icons/si'

export const skillsArray =  [
    {
        skill: 'HTML5',
        icon: <FaHtml5 />,
        color: `#EF6530`
    },
    {
        skill: 'CSS3',
        icon: <FaCss3 />,
        color: `#1F72B6`
    },
    {
        skill: 'SCSS',
        icon: <FaSass />,
        color: `#CC6699`
    },
    {
        skill: 'Javascript',
        icon: <SiJavascript />,
        color: `#F7DF32`
    },
    {
        skill: 'Typescript',
        icon: <SiTypescript />,
        color: `#1E7ACC`
    },
    {
        skill: 'React',
        icon: <FaReact />,
        color: `#61DAFB`
    },
    {
        skill: 'Node',
        icon: <FaNodeJs />,
        color: `#86BF17`
    },
    {
        skill: 'MongoDB',
        icon: <SiMongodb />,
        color: `#579234`
    },
    {
        skill: 'GitHub',
        icon: <FaGithub />,
        color: `#FFFFFF`
    }
]