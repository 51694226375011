import styled from 'styled-components'

export const StyledContact = styled('div')`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
height: 200px;
background-color: ${props => props.theme.dark};
`

export const Icons = styled('div')`
display: flex;
justify-content: space-between;
width: 50vw;
min-width: 250px;
margin-bottom: 1rem;

svg {
    color: whitesmoke;
    cursor: pointer;
}

svg:hover {
    color: ${({theme: {primaryColor}}) => primaryColor};
}

@media screen and (max-width: 600px) {
    width: 90%;
}
`