import styled from 'styled-components'

export const StyledProjectBox = styled('div')<{index: number}>`
position: relative;
display: grid;
place-items: center;
box-shadow: 5px 5px 5px rgba(0,0,0,.5);
overflow: hidden;
cursor: pointer;
`

export const ProjectImage = styled('img')`
height: 100%;
width: 100%;
object-fit: cover;
transition: transform .5s ease-in-out, filter .5s ease-in-out;
`

export const Title = styled('p')`
position: relative;
text-align: center;
font-size: 2rem;
font-family: 'Titillium Web', sans-serif;

@media screen and (max-width: 500px) {
    font-size: 1rem;
}
`

export const Description = styled('p')`
text-align: center;
`

export const ProjectInfo = styled('div')`
position: absolute;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
height: 100%;
width: 100%;
opacity: 0;
background-color: white;
transition: opacity 1s ease-in-out;

&:hover {
    opacity: 1;
}
`

export const ProjectLinks = styled('div')`
text-align: center;
display: flex;
justify-content: space-evenly;
width: 100%;

svg {
    margin: 0 5px;
}
`