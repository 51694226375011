import styled from 'styled-components'

export const StyledDivider = styled('div')<{offset: number, state: string}>`
position: relative;
top: ${props => props.offset + 'rem'};
display: flex;
flex-direction: column;
height: 3rem;
width: 200px;
padding: .5rem;
background-color: ${props => props.theme.primaryColor};
box-shadow: 0px 1px 15px rgba(0,0,0,.5), 0px -1px 15px rgba(0,0,0,.5);
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
z-index: 1;
opacity: ${props => {
    switch(props.state) {
        case 'entering':
            return 1
        case 'entered':
            return 1
        case 'exiting':
            return 0
        case 'exited':
            return 0
        default:
            return 0
    }
}};
transition: opacity 1s ease-in-out;
`

export const DividerTitle = styled('p')`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
font-family: 'Titillium Web', sans-serif;
font-size: 2rem;
font-weight: bold;
color: ${props => props.theme.dark};
`