import styled from 'styled-components'

export const headerFadeInDuration = 1000

export const HeaderStyled = styled('header')<{expand: boolean}>`
position: fixed;
top: 0;
height: ${props => props.expand ? '100vh' : '4rem'};
width: 100%;
display: flex;
background: ${props => props.theme.primaryColor};
color: ${props => props.theme.dark};
box-shadow: 0px 1px 5px black;
z-index: 3;
transition:  height 500ms ease-in-out;
`

export const HeaderLogo = styled('div')`
position: absolute;
top: 0;
left: .5rem;
display: flex;
align-items: center;
height: 4rem;
font-family: 'Titillium Web', sans-serif;
cursor: pointer;
font-size: 1.5rem;

&:first-child {
margin-left: 1rem;
}
`

export const HeaderNav = styled('nav')`
position: absolute;
top: 50%;
right: 0;
transform: translateY(-50%);
display: flex;
align-items: center;
height: 100%;
width: 50%;
font-family: 'Titillium Web', sans-serif;

ul {
    list-style: none;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

li {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
}

li:hover {
    color: ${({theme: {primaryColor}}) => primaryColor};
    background: ${({theme: {dark}}) => dark};
}

@media screen and (max-width: 900px) {
width: 60%;
}

@media screen and (max-width: 750px) {
    display: none;
}
`
export const HeaderNavLi = styled('li')`
display: inline-block;
font-size: 1.5rem;
text-transform: uppercase;
cursor: pointer;
`

export const Hamburger = styled('div')<{menuOpen: boolean}>`
position: absolute;
display: none;
right: 2rem;
top: 1rem;

@media screen and (max-width: 750px) {
display: flex;
}
`

export const HiddenMenu = styled('div')<{menuOpen: boolean}>`
visibility: ${({menuOpen}) => menuOpen ? 'visible' : 'hidden'};
opacity: ${({menuOpen}) => menuOpen ? 1 : 0};
position: absolute;
bottom: 0;
display: flex;
flex-direction: column;
align-items: center;
height: calc(100% - 4rem);
width: 100%;
font-family: 'Titillium Web', sans-serif;
overflow: hidden;
transition: all 1s ease-in-out;
border-top: ${({theme: {dark}}) => `1px solid ${dark}`};

ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
}

li {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
}
`