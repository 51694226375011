import styled from 'styled-components';

export const StyledSkillsSquare = styled('div')<{color: string, title: string}>`
position: relative;
display: flex;
top: 0;
flex-direction: column;
align-items: center;
justify-content: center;
box-shadow: 5px 5px 5px rgba(0,0,0,.5);
background-color: ${({theme: {dark}}) => dark};
margin: 5px;
padding: .5rem;
transition: 1s background-color ease-in-out;

svg {
height: auto;
width: 8rem;
color: ${({color}) => color};
transition: 1s color ease-in-out;
}

@media screen and (max-width: 600px) {
    svg {
        height: auto;
        width: 6rem;
    }
}

@media screen and (max-width: 400px) {
    svg {
        height: auto;
        width: 4rem;
    }
}

&:hover {
    background-color: ${({color}) => color};

    svg {
        color: ${({theme: {dark}}) => dark};
    }
}
`