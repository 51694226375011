import styled, {keyframes} from "styled-components"

const leftPosterAnim = keyframes`
from {
    transform: rotate(-15deg) translateY(-50%);
    z-index: 0;
}
to {
    transform: scale(1.25) rotate(-15deg) translateY(-50%);
    z-index: 2;
}
`
const middlePosterAnim = keyframes`
from {
    transform: translateX(-50%) translateY(-50%);
}
to {
    transform: scale(1.25) translateX(-40%) translateY(-50%);
}
`
const rightPosterAnim = keyframes`
from {
    transform: rotate(15deg) translateY(-50%);
    z-index: 0;
}
to {
    transform: scale(1.25) rotate(15deg) translateY(-50%);
    z-index: 1;
}
`

export const PosterContainer = styled('div')`
position: relative;
display: flex;
height: 100%;
width: 100%;
`

export const PosterLeft = styled('div')<{image: string}>`
position: absolute;
top: 50%;
left: 0;
transform: rotate(-15deg) translateY(-50%);
height: 160px;
width: 120px;
background-image: ${({image}) => `url(${image})`};
background-position: center;
background-repeat: no-repeat;
background-size: cover;
cursor: pointer;

&:hover {
    animation: .5s ${leftPosterAnim} forwards;
}

@media screen and (max-width: 800px) {
    height: calc(160px * .9);
    width: calc(120px * .9);
}

@media screen and (max-width: 600px) {
    height: calc(160px * .7);
    width: calc(120px * .7);
}

@media screen and (max-width: 400px) {
    height: calc(160px * .5);
    width: calc(120px * .5);
}
`

export const PosterMiddle = styled('div')<{image: string}>`
position: absolute;
top: 45%;
left: 50%;
transform: translate(-50%, -50%);
height: 180px;
width: 135px;
background-image: ${({image}) => `url(${image})`};
background-position: center;
background-repeat: no-repeat;
background-size: cover;
cursor: pointer;
z-index: 1;

&:hover {
    animation: .5s ${middlePosterAnim} forwards;
}

@media screen and (max-width: 800px) {
    height: calc(180px * .9);
    width: calc(135px * .9);
}

@media screen and (max-width: 600px) {
    height: calc(180px * .7);
    width: calc(135px * .7);
}

@media screen and (max-width: 400px) {
    height: calc(180px * .5);
    width: calc(135px * .5);
}
`

export const PosterRight = styled('div')<{image: string}>`
position: absolute;
top: 50%;
right: 0;
transform: rotate(15deg) translateY(-50%);
height: 160px;
width: 120px;
background-image: ${({image}) => `url(${image})`};
background-position: center;
background-repeat: no-repeat;
background-size: cover;
cursor: pointer;

&:hover {
    animation: .5s ${rightPosterAnim} forwards;
}

@media screen and (max-width: 800px) {
    height: calc(160px * .9);
    width: calc(120px * .9);
}

@media screen and (max-width: 600px) {
    height: calc(160px * .7);
    width: calc(120px * .7);
}

@media screen and (max-width: 400px) {
    height: calc(160px * .5);
    width: calc(120px * .5);
}
`