import React from 'react'
import {StyledSkillsSquare} from './SkillsSquare.styles'

type Props = {
    color: string,
    title: string,
}

const SkillsBox: React.FC<Props> = ({children, color, title}) => {
    return (
        <StyledSkillsSquare color={color} title={title}>
            {children}
        </StyledSkillsSquare>
    )
}

export default SkillsBox
