import React from 'react'

import { StyledAbout, Me, AboutGrid, AboutBox, BoxTitle, BoxContents, Bio } from './About.styles';
import Posters from './Posters/Posters';
import me from '../../images/me-4.jpg'
import house from '../../images/house-poster.jpg'
import lost from '../../images/lost-poster.jpg'
import office from '../../images/office-poster.jpg'
import eternal from '../../images/eternal-poster.jpg'
import vanilla from '../../images/vanilla-sky-poster.jpg'
import notebook from '../../images/the-notebook-poster.jpg'

const shows = {
    image1: lost,
    image2: house,
    image3: office
}

const movies = {
    image1: vanilla,
    image2: eternal,
    image3: notebook
}

type Props = {
    refs: any
}

const About: React.FC<Props> = ({ refs }) => {
    return (
            <StyledAbout ref={refs.about}>
                <AboutGrid>
                    <Me me={me} />
                    <AboutBox>
                        <BoxTitle>Bio</BoxTitle>
                        <BoxContents>
                            <Bio>
                                <ul>
                                    <li><p>Name: </p><p>Jason Shaw</p></li>
                                    <li><p>Location: </p><p>Palmdale, CA</p></li>
                                    <li><p>Hobbies: </p><p>Playing Pool, video games</p></li>
                                    <li><q>I love to code with JavaScript/TypeScript and React.js.</q></li>
                                </ul>
                            </Bio>
                        </BoxContents>
                    </AboutBox>
                    <AboutBox>
                        <BoxTitle>Favorite Shows</BoxTitle>
                        <BoxContents>
                        <Posters images={shows}/>
                        </BoxContents>
                    </AboutBox>
                    <AboutBox>
                        <BoxTitle>Favorite Movies</BoxTitle>
                        <BoxContents>
                        <Posters images={movies}/>
                        </BoxContents>
                    </AboutBox>
                </AboutGrid>
            </StyledAbout>
    )
}

export default About
