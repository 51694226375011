import React from 'react'

import { Refs } from '../@types/myTypes'
import About from '../components/About'
import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout'
import Projects from '../components/Projects'
import Skills from '../components/Skills'
import Contact from '../components/Contact/index';
import Divider from '../components/Divider'

const Home: React.FC = () => {
    const refs: Refs = {
        skills: React.useRef<HTMLDivElement>(null),
        projects: React.useRef<HTMLDivElement>(null),
        about: React.useRef<HTMLDivElement>(null),
        contact: React.useRef<HTMLDivElement>(null)
    }

    return (
        <Layout>
            <Hero refs={refs} />
            <Divider offset={0} title={'Skills'} />
            <Skills refs={refs} />
            <Divider offset={3} title={'Projects'} />
            <Projects refs={refs} />
            <Divider offset={0} title={'About'} />
            <About refs={refs} />
            <Contact refs={refs} />
        </Layout>
    )
}

export default Home
