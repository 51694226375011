import styled from 'styled-components'

export const ScrollToTopFadeInDuration = 300

export const StyledScrollToTop = styled('div')<{state: string}>`
position: fixed;
right: 2rem;
bottom: 2rem;
display: grid;
place-items: center;
height: 3rem;
width: 3rem;
border-radius: 50%;
background-color: ${props => props.theme.primaryColor};
box-shadow: 1px 1px 5px black;
cursor: pointer;
z-index: 2;
opacity: ${props => {
    switch(props.state) {
        case 'entering':
            return 1
        case 'entered':
            return 1
        case 'exiting':
            return 0
        case 'exited':
            return 0
        default:
            return 0
    }
}};
transition: opacity ${ScrollToTopFadeInDuration}ms ease-in-out;

svg {
    color: ${props => props.theme.dark};
}
`